export const Color = {
  GREEN_PRIMARY: "#5a9d32",
  GREEN_LIGHT: "#8bce61",
  ORANGE_PRIMARY: "#f2521b",
  ORANGE_LIGHT: "#ff854a",
  BACKGROUND_DARK_PRIMARY: "#191919",
  BACKGROUND_WHITE_PRIMARY: "#e9e9e9",
  TEXT_WHITE_PRIMARY: "rgba(255, 255, 255, 0.9)",
  TEXT_BLACK_PRIMARY: "rgba(0, 0, 0, 0.9)"
};
