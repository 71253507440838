export const GRECAPTCHA_ACTION_BOOK = "book";
export const GRECAPTCHA_ACTION_SIGNUP = "signup";

export const GRECAPTCHA_SITE_KEY = (() => {
  if (!process.env.GATSBY_GRECAPTCHA_SITE_KEY) {
    throw new Error("GATSBY_GRECAPTCHA_SITE_KEY not set");
  }
  return process.env.GATSBY_GRECAPTCHA_SITE_KEY;
})();

export const executeGrecaptcha = (action) => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(GRECAPTCHA_SITE_KEY, {
          action,
        })
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};
