import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Color } from "../../../styles";
import { GRECAPTCHA_SITE_KEY } from "../../../services/GrecaptchaService";

export default class CustomHelmet extends React.Component {
  render() {
    return (
      <Helmet
        charSet="utf-8"
        title={this.props.title}
        meta={[
          { name: "description", content: this.props.description },
          { name: "keywords", content: this.props.keywords },
          { name: "msapplication-TileColor", content: "#333333" },
          { name: "theme-color", content: Color.ORANGE_PRIMARY },
        ]}
        link={[
          {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/apple-touch-icon.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "/favicon-32x32.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "/favicon-16x16.png",
          },
          {
            rel: "mask-icon",
            href: "/safari-pinned-tab.svg",
            color: Color.ORANGE_PRIMARY,
          },
          {
            rel: "stylesheet",
            href: "https://fonts.googleapis.com/css?family=Raleway:300,400,600,700",
          },
          {
            rel: "canonical",
            href: "https://platoon.se" + this.props.canonical,
          },
        ]}
        script={[
          {
            src: `https://www.google.com/recaptcha/api.js?render=${GRECAPTCHA_SITE_KEY}`,
          },
        ]}
      />
    );
  }
}

CustomHelmet.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
  canonical: PropTypes.string.isRequired,
};
